
import React from 'react';
import { Link } from 'gatsby';
import styled from '@emotion/styled';

import Image from '@components/Image';
import OrderedList from '@components/Lists/List.Ordered.tsx';
import Anchor from '@components/Anchor';

const Bio = ({ author }) => {
  return (
    <BioParentContainer>
      <BioContainer>
        <BioAvatar
          as={author.authorsPage ? Link : 'div'}
          to={author.slug}
          data-a11y="false"
          aria-label="Author's bio"
        >
          <BioAvatarInner>
            <RoundedImage src={author.avatar.medium} />
          </BioAvatarInner>
        </BioAvatar>
        <BioText dangerouslySetInnerHTML={{ __html: author.bio }} />
      </BioContainer>

      <OrderedList>
        <li><Anchor href="/the-fundamentals-of-losing-fat-and-getting-fit">The fundamentals of losing fat and getting fit</Anchor></li>
        <li><Anchor href="/how-to-count-your-calories">How to count your calories</Anchor></li>
        <li><Anchor href="/how-to-estimate-your-calorie-expenditure">How to estimate your calorie expenditure</Anchor></li>
        <li><Anchor href="/nutrition-strategies-during-a-calorie-deficit">Nutrition strategies during a calorie deficit</Anchor></li>
      </OrderedList>
    </BioParentContainer>
  );
};

export default Bio;

const BioParentContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const BioContainer = styled.div`
  display: flex;
  align-items: center;
  position: relative;
  left: -10px;
`;

const BioAvatar = styled.div`
  display: block;
  position: relative;
  height: 40px;
  width: 40px;
  border-radius: 50%;
  background: rgba(0, 0, 0, 0.25);
  margin-right: 16px;
  margin: 10px 26px 10px 10px;
  &::after {
    content: '';
    position: absolute;
    left: -5px;
    top: -5px;
    width: 50px;
    height: 50px;
    border-radius: 50%;
    border: 1px solid rgba(0, 0, 0, 0.25);
  }
  &[data-a11y='true']:focus::after {
    content: '';
    position: absolute;
    left: -5px;
    top: -5px;
    width: 50px;
    height: 50px;
    border: 2px solid ${p => p.theme.colors.accent};
  }
`;

const RoundedImage = styled(Image)`
  border-radius: 50%;
`;

const BioAvatarInner = styled.div`
  height: 40px;
  width: 40px;
  border-radius: 50%;
  background: rgba(0, 0, 0, 0.25);
  margin-right: 16px;
  overflow: hidden;
`;

const BioText = styled.p`
  max-width: 430px;
  font-size: 14px;
  line-height: 1.45;
  color: ${p => p.theme.colors.grey};
  a {
    color: ${p => p.theme.colors.grey};
    text-decoration: underline;
  }
`;
